import React from 'react';

import Seo from '../components/seo';

const NotFoundPage = () => (
  <>
    <Seo title="404 | Zenn HRB" />
    <section>
      <div>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </>
);

export default NotFoundPage;
